<script>
  import MaskInput from 'svelte-input-mask/MaskInput.svelte';
  import { t } from '../utils/index.ts';
  import AddressForm from '../components/AddressForm.svelte';
  import PaymentRequestButton from '../components/PaymentRequestButton.svelte';
  import { activeStep, steps } from '../stores/state.store.ts';

  export let stripe;
  export let donation;

  let showPaymentRequestButton = false;
  let addressComponent;
  let form;

  function onFormSubmit(event) {
    updateDonationModelFromForm(event.target);
    activeStep.set(steps.Payment);
  }

  function updateDonationModelFromForm(form) {
    const data = new FormData(form);

    data.forEach((value, key) => {
      donation[key] = value;
    });
  }

  function handlePhoneChange({ detail }) {
    donation.phone = detail.inputState.maskedValue;
  }

  function handlePhoneFocus(event) {
    window.setTimeout(() => {
      donation.phone = event?.detail?.target?.value;
    }, 0);
  }
</script>

<PaymentRequestButton {stripe} {donation} on:showMe={(e) => (showPaymentRequestButton = e)} />

<form
  class="uweo-donation__form"
  bind:this={form}
  on:input={(event) => updateDonationModelFromForm(event.target.form)}
  on:submit|preventDefault={onFormSubmit}
>
  <fieldset class="uweo-donation__fieldset">
    <legend class="uweo-donation__legend">
      {@html t('Contact Information')}
    </legend>
    <div class="uweo-donation__form-row">
      <div class="uweo-donation__col-50">
        <div class="uweo-donation__form-group">
          <label for="email-address">
            <small>
              {@html t('Email address')}
            </small>
          </label>
          <input type="email" id="email-address" required bind:value={donation.email} on:focus={updateDonationModelFromForm(form)} />
        </div>
      </div>
      <div class="uweo-donation__col-50">
        <div class="uweo-donation__form-group">
          <label for="phone-number">
            <small>
              {@html t('Phone')}
              <small>(555-555-5555)</small>
            </small>
          </label>
          <!-- <input type="tel" id="phone-number" maxlength="25" required bind:value={donation.phone} /> -->
          <MaskInput
            type="tel"
            required
            pattern="\d\d\d-\d\d\d-\d\d\d\d"
            min="12"
            alwaysShowMask
            mask="000-000-0000"
            size={20}
            showMask
            maskChar="_"
            value={donation.phone || undefined}
            on:change={handlePhoneChange}
            on:focus={(evt) => {
              handlePhoneFocus(evt);
              updateDonationModelFromForm(form);
            }}
          />
        </div>
      </div>
    </div>
  </fieldset>
  <!-- <div class="uweo-donation__form-row" style="margin-bottom: 1em;">
    <div class="uweo-donation__col">
      <div class="uweo-donation__form-check">
        <input type="checkbox" id="phone_optin" name="phone_optin" bind:checked={donation.phone_optin} />
        <label class="form-check-label" for="phone_optin">
          <b>
            {@html t('Yes, it&lsquo;s okay to contact me by phone.')}
          </b>
        </label>
      </div>
    </div>
  </div> -->
  <fieldset class="uweo-donation__fieldset">
    <legend class="uweo-donation__legend">
      {@html t('Billing Address')}
    </legend>
    <AddressForm
      bind:this={addressComponent}
      bind:address={donation}
      on:inputUpdate={() => updateDonationModelFromForm(form)}
      required={true}
    />
  </fieldset>

  <div class="uweo-donation__actions">
    <button
      type="button"
      class="uweo-donation__prev-step-button"
      on:click={() => {
        activeStep.set(steps.Options);
      }}
    >
      <img
        class="uweo-donation__prev-arrow"
        src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHdpZHRoPScyNycgaGVpZ2h0PSczMCcgdmlld0JveD0nMCAwIDQ0OCA1MTInPjxwYXRoIGQ9J00zMTEuMDMgMTMxLjUxNWwtNy4wNzEgNy4wN2MtNC42ODYgNC42ODYtNC42ODYgMTIuMjg0IDAgMTYuOTcxTDM4Ny44ODcgMjM5SDEyYy02LjYyNyAwLTEyIDUuMzczLTEyIDEydjEwYzAgNi42MjcgNS4zNzMgMTIgMTIgMTJoMzc1Ljg4N2wtODMuOTI4IDgzLjQ0NGMtNC42ODYgNC42ODYtNC42ODYgMTIuMjg0IDAgMTYuOTcxbDcuMDcxIDcuMDdjNC42ODYgNC42ODYgMTIuMjg0IDQuNjg2IDE2Ljk3IDBsMTE2LjQ4NS0xMTZjNC42ODYtNC42ODYgNC42ODYtMTIuMjg0IDAtMTYuOTcxTDMyOCAxMzEuNTE1Yy00LjY4Ni00LjY4Ny0xMi4yODQtNC42ODctMTYuOTcgMHonLz48L3N2Zz4="
        alt="Previous"
      />
      <span>
        {@html t('Back')}
      </span>
    </button>
    <button
      class="uweo-donation__next-step-button"
      on:click={() => {
        activeStep.set(steps.Info);
      }}
    >
      <span>
        {@html t('Continue to payment')}
      </span>
      <img
        class="uweo-donation__next-arrow"
        src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHdpZHRoPScyNycgaGVpZ2h0PSczMCcgdmlld0JveD0nMCAwIDQ0OCA1MTInPjxwYXRoIGQ9J00zMTEuMDMgMTMxLjUxNWwtNy4wNzEgNy4wN2MtNC42ODYgNC42ODYtNC42ODYgMTIuMjg0IDAgMTYuOTcxTDM4Ny44ODcgMjM5SDEyYy02LjYyNyAwLTEyIDUuMzczLTEyIDEydjEwYzAgNi42MjcgNS4zNzMgMTIgMTIgMTJoMzc1Ljg4N2wtODMuOTI4IDgzLjQ0NGMtNC42ODYgNC42ODYtNC42ODYgMTIuMjg0IDAgMTYuOTcxbDcuMDcxIDcuMDdjNC42ODYgNC42ODYgMTIuMjg0IDQuNjg2IDE2Ljk3IDBsMTE2LjQ4NS0xMTZjNC42ODYtNC42ODYgNC42ODYtMTIuMjg0IDAtMTYuOTcxTDMyOCAxMzEuNTE1Yy00LjY4Ni00LjY4Ny0xMi4yODQtNC42ODctMTYuOTcgMHonLz48L3N2Zz4="
        alt="Next"
      />
    </button>
  </div>
</form>
