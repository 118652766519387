export const env = {
    apiUrl: "https://api.unitedwayeo.ca/api/public",
    local: false,
    production: true,
    recaptchaKey: "6Ldf4-UUAAAAADXzFlyepfX29_rDgKCuR5Q1Pr9n",
    addressCompleteKey: "hu99-re78-jd38-tn17",
    thankYouUrl: {
        en: {
            month: "/donate/monthly-thank-you/",
            none: "/donate/thank-you/",
        },
        fr: {
            month: "/fr/don/mensuel-merci/",
            none: "/fr/don/merci/",
        },
    },
};
