<script>
  import { activeStep, steps } from "../stores/state.store.ts";
  import { t } from "../utils/index.ts";
</script>

<div>
  <ul class="uweo-donation_steps">
    <li class="uweo-donation_step" class:uweo-donation_step--active={$activeStep === steps.Amount}>{t('Amount')}</li>
    <li class="uweo-donation_step" class:uweo-donation_step--active={$activeStep === steps.Options}>{t('Options')}</li>
    <li class="uweo-donation_step" class:uweo-donation_step--active={$activeStep === steps.Info}>{t('Information')}</li>
    <li class="uweo-donation_step" class:uweo-donation_step--active={$activeStep === steps.Payment}>{t('Payment')}</li>
  </ul>
</div>
