<script>
  import { onMount } from 'svelte';
  import { amount as donationAmount } from '../stores/state.store.ts';

  export let amounts = [];
  let amount = undefined;

  onMount(() => {
    amount = amounts.find((a) => a === $donationAmount) ? null : $donationAmount;
  });

  function onChange() {
    if (!amount) {
      return;
    }

    // Round amount to nearest integer
    if (Number.isInteger(amount) === false) {
      amount = Math.floor(amount);
    }

    donationAmount.set(amount);
  }
</script>

<input
  class="uweo-donation__amount-input"
  bind:value={amount}
  on:focus={onChange}
  on:keyup={onChange}
  on:blur={onChange}
  class:active={amount === $donationAmount}
  min="5"
  step="1"
  type="number"
  placeholder="$"
/>
