export var CanadianProvince;
(function (CanadianProvince) {
    CanadianProvince["Alberta"] = "AB";
    CanadianProvince["BritishColumbia"] = "BC";
    CanadianProvince["Manitoba"] = "MB";
    CanadianProvince["NewBrunswick"] = "NB";
    CanadianProvince["Newfoundland"] = "NL";
    CanadianProvince["NovaScotia"] = "NS";
    CanadianProvince["NorthwestTerritories"] = "NT";
    CanadianProvince["Nunavut"] = "NU";
    CanadianProvince["Ontario"] = "ON";
    CanadianProvince["PEI"] = "PE";
    CanadianProvince["Quebec"] = "QC";
    CanadianProvince["Saskatchewan"] = "SK";
    CanadianProvince["Yukon"] = "YT";
})(CanadianProvince || (CanadianProvince = {}));
export const CanadianProvinceDictionary = {
    [CanadianProvince.Alberta]: 'Alberta',
    [CanadianProvince.BritishColumbia]: 'British Columbia',
    [CanadianProvince.Manitoba]: 'Manitoba',
    [CanadianProvince.NewBrunswick]: 'New Brunswick',
    [CanadianProvince.Newfoundland]: 'Newfoundland and Labrador',
    [CanadianProvince.NovaScotia]: 'Nova Scotia',
    [CanadianProvince.NorthwestTerritories]: 'Northwest Territories',
    [CanadianProvince.Nunavut]: 'Nunavut',
    [CanadianProvince.Ontario]: 'Ontario',
    [CanadianProvince.PEI]: 'Prince Edward Island',
    [CanadianProvince.Quebec]: 'Quebec',
    [CanadianProvince.Saskatchewan]: 'Saskatchewan',
    [CanadianProvince.Yukon]: 'Yukon',
};
export const Provinces = Object.keys(CanadianProvinceDictionary).map(key => ({
    label: CanadianProvinceDictionary[key],
    value: key,
}));
