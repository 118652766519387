<script lang="ts">
  import { t } from "../utils/index";
  import AmountButton from "../components/AmountButton.svelte";
  import AmountInput from "../components/AmountInput.svelte";
  import {
    activeStep,
    amount,
    singleAmounts,
    monthlyAmounts,
    isMonthly,
    steps,
    amounts,
  } from "../stores/state.store";

  export let title;
  export let intro;
</script>

<div class="uweo-donation__row">
  <div class="uweo-donation__col uweo-donation__title-group">
    <h1 class="uweo-donation__title">{title}</h1>
    <p class="uweo-donation__lead">{intro}</p>
  </div>
</div>

<form on:submit|preventDefault={() => activeStep.set(steps.Options)}>
  <div class="border-stone-300 overflow-hidden border border-solid rounded-md">
    <div class="">
      <div class="flex" aria-label="Tabs">
        <!-- Current: "text-gray-900", Default: "text-gray-500 hover:text-gray-700" -->
        <button
          class="group tab"
          aria-current="page"
          type="button"
          class:active={$isMonthly}
          on:click={() => isMonthly.set(true)}
        >
          <span>{t("Monthly")}</span>
        </button>

        <button
          type="button"
          class="group tab"
          class:active={!$isMonthly}
          on:click={() => isMonthly.set(false)}
        >
          <span>{t("One-time")}</span>
        </button>
      </div>
    </div>
    <div class="uweo-donation__row p-6 mb-0">
      <div class="uweo-donation__col">
        {#if $isMonthly}
          <h2 class="uweo-donation__donation-title">
            {@html t(
              "By joining GenNext+, your monthly coffee budget can make a huge impact on the lives of local people."
            )}
          </h2>
          <div class="uweo-donation__amount-button-group">
            {#each monthlyAmounts as amount}
              <AmountButton {amount} />
            {/each}
            <AmountInput {amounts} />
          </div>
        {:else}
          <h2 class="uweo-donation__donation-title">
            {@html t("Choose how you’d like to make change:")}
          </h2>
          <div class="uweo-donation__amount-button-group">
            {#each singleAmounts as amount}
              <AmountButton {amount} />
            {/each}
            <AmountInput {amounts} />
          </div>
        {/if}
      </div>

      {#if $amount < 5}
        <div
          style="font-size: smaller; color: #e2211c; text-align: center; font-weight: bold;"
        >
          {@html t("Our minimum online donation amount is $5.00.")}
        </div>
      {/if}
    </div>
  </div>

  <div class="uweo-donation__actions">
    <button
      disabled={$amount < 5}
      class="uweo-donation__next-step-button uweo-donation__donate-button"
      type="submit"
    >
      <span>
        {@html t("Donate")}
      </span>
      <img
        class="uweo-donation__next-arrow"
        src="data:image/svg+xml;base64,
        PHN2ZyBpZD0iZTQ5ODgzNDEtODYwZC00ZTNjLThjNTctZWQ0MDFmZTUzMjg2IiBkYXRhLW5hbWU9IkxheWVyIDEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjAuMzZpbiIgaGVpZ2h0PSIwLjIxaW4iIHZpZXdCb3g9IjAgMCAyNi4yNSAxNSI+PGRlZnM+PHN0eWxlPi5hMjM1NWEzNy0zNjIyLTQ1N2MtODFmNS0zMWQ0ZWYxMDkwY2J7ZmlsbDojZmZmO308L3N0eWxlPjwvZGVmcz48dGl0bGU+YXJyb3ctd2hpdGU8L3RpdGxlPjxwYXRoIGNsYXNzPSJhMjM1NWEzNy0zNjIyLTQ1N2MtODFmNS0zMWQ0ZWYxMDkwY2IiIGQ9Ik0xOC42LDcuNzFsLS40MS40MWEuNjkuNjksMCwwLDAsMCwxTDIzLjEsMTRoLTIyYS43LjcsMCwwLDAtLjcuNzF2LjU4YS43LjcsMCwwLDAsLjcuNzFoMjJsLTQuOTEsNC44OWEuNjkuNjksMCwwLDAsMCwxbC40MS40MWEuNjkuNjksMCwwLDAsMSwwbDYuODMtNi43OWEuNzIuNzIsMCwwLDAsMC0xTDE5LjU5LDcuNzFBLjY5LjY5LDAsMCwwLDE4LjYsNy43MVoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0wLjM4IC03LjUpIi8+PC9zdmc+"
        alt="Next"
      />
    </button>
  </div>
</form>

<style lang="postcss">
  .tab {
    @apply hover:text-stone-700 hover:bg-stone-50 focus:z-10 relative flex-1 min-w-0 px-4 py-4 text-center text-stone-500 bg-stone-100 border-0 font-bold text-xl focus:outline-none focus:bg-uw-50;

    &.active {
      @apply bg-uw-500 text-white after:content-[''] relative;
      @apply after:absolute after:w-0 after:h-0 after:border-uw after:border-solid after:border-x-[20px] after:border-x-transparent after:border-t-[20px] after:border-t-uw after:top-full after:left-1/2 after:-translate-x-1/2 after:border-b-0;
    }
  }

  .uweo-donation__donation-title {
    @apply mb-8;
  }

  .uweo-donation__donate-button {
    @apply mb-8;
  }
</style>
