export var Country;
(function (Country) {
    Country["Canada"] = "Canada";
    Country["USA"] = "United States";
})(Country || (Country = {}));
export const CountriesDictionary = {
    [Country.Canada]: 'Canada',
    [Country.USA]: 'United States',
};
export const CountriesCode = {
    [Country.Canada]: 'CA',
    [Country.USA]: 'US',
};
export const Countries = Object.keys(CountriesDictionary).map((key) => ({
    label: CountriesDictionary[key],
    value: key,
}));
