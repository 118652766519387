import { getLocale } from './locale';
export function formatCurrency(amount, digits = 2) {
    return amount.toLocaleString(getLocale(), {
        minimumFractionDigits: digits,
        currency: 'CAD',
        // currencyDisplay: 'symbol',
        // currencySign:"symbol",
        useGrouping: true,
        style: 'currency',
    });
}
