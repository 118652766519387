<script>
  import { t } from "../utils/index.ts";

  import BottomBar from "./BottomBar.svelte";
  import Logo from "./Logo.svelte";
</script>

<div class="mt-6">
  <BottomBar />
  <div class="p-12 text-xs">
    <p class="mb-6">
      {@html t("This site is protected by reCAPTCHA and the Google ")}
      <a class="link" href="https://policies.google.com/policy">
        {@html t("Privacy Policy")}
      </a>
      {@html t(" and ")}
      <a class="link" href="https://policies.google.com/terms">
        {@html t("Terms of Service")}
      </a>
      {@html t(" apply.")}
    </p>
    <div class="flex justify-between">
      <div class="basis-1/3">
        <p>
          <span class="font-bold uppercase">
            {@html t("Powered by")}
          </span><br />
          <span class="font-bold uppercase">
            {@html t("United Way East Ontario")}
          </span>
          <br />
          <span>
            {@html t("363 Coventry Road")}
          </span>
          <br />
          <span>
            {@html t("Ottawa, ON K1K 2C5")}
          </span>
        </p>
      </div>
      <div class="basis-1/3">
        <p>
          <span class="font-bold uppercase">
            {@html t("Need help?")}
          </span>
          <br />
          <a class="link" href="mailto:{t('info@unitedwayeo.ca')}">
            {@html t("info@unitedwayeo.ca")}
          </a>
          <br />
          <a class="link" href="tel:{t('613.228.6767')}">
            {@html t("613.228.6767")}
          </a>
        </p>
      </div>
      <div class="basis-1/3">
        <p>
          <a href={t("/accessibility")} class="link font-bold uppercase">
            {@html t("Accessibility")}
          </a>
          <br />
          <a href={t("/terms")} class="link font-bold uppercase">
            {@html t("Terms Of Use")}
          </a>
          <br />
          <a href={t("/privacy")} class="link font-bold uppercase">
            {@html t("Privacy")}
          </a>
        </p>
      </div>
    </div>

    <div class="flex items-center mt-6">
      <div class="basis-1/4 max-w-xs pr-3">
        <Logo />
      </div>
      <div class="basis-2/3">
        <p class="text-right">
          <span>© {new Date().getFullYear() + " "}</span>
          <span>
            {@html t("United Way East Ontario. All rights reserved.")}
          </span>
          <br />
          <span>
            {@html t("BN/Registration Number: 108160250RR0001")}
          </span>
        </p>
      </div>
    </div>
  </div>
</div>
