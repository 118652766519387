import { errors } from '../../stores/state.store';
import { env } from '../../environments/environment';
import { analytics } from '../analytics';
import { apiLang } from '../locale';
import { CountriesCode } from '../locations';
export async function postDonation(campaignId, donation) {
    // Cleanup dedication since API rejects non-NULL values if not complete
    if (donation.attributes) {
        if (!donation.attributes.dedication.recipients_name) {
            donation.attributes.dedication.recipients_name = donation.attributes.dedication.in_memory_of;
        }
        if (!donation.attributes.dedication.send_date) {
            const d = new Date();
            donation.attributes.dedication.send_date = `${d.getUTCFullYear()}-${d.getUTCMonth() + 1}-${d.getUTCDate()}`;
        }
        if (!donation.attributes.inTribute) {
            donation.attributes.dedication = null;
            donation.attributes.shouldNotify = false;
        }
        if (!donation.attributes.isCorporate) {
            donation.attributes.workplace = null;
        }
    }
    donation.attributes.country = CountriesCode[donation.attributes.country] || donation.attributes.country;
    donation.attributes['g-recaptcha-response'] = await grecaptcha
        .execute(env.recaptchaKey, { action: 'donate' })
        .then((token) => token);
    return fetch(`${env.apiUrl}/campaigns/${campaignId}/donations`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept-Language': apiLang(),
        },
        body: JSON.stringify({ data: donation }),
    })
        .then((response) => response.json())
        .then((res) => {
        if (res.errors) {
            errors.set(res.errors.map((e) => e.detail));
            throw res;
        }
        else {
            analytics(campaignId, donation);
            return res;
        }
    });
}
