import { Interval } from './interval.enum';
import { postDonation } from './post-donation';
export function handleStripeToken(stripeRes, campaignId, donation, amount, isMonthly) {
    const payload = {
        id: null,
        type: 'donations',
        attributes: Object.assign(Object.assign({}, donation), { amount: amount, interval: isMonthly ? Interval.Month : Interval.None, token: stripeRes.token.id }),
    };
    return postDonation(campaignId, payload);
}
