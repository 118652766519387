import { env } from '../../environments/environment';
import { apiLang } from '../locale';
export async function getEcardOptions() {
    const response = await fetch(`${env.apiUrl}/ecards`, {
        headers: {
            'Accept-Language': apiLang(),
        },
    });
    const options = await response.json();
    return options;
}
