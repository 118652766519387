<script>
  import { onMount, onDestroy } from 'svelte';

  import { handleExpressDonation, t, getLocale } from '../utils/index.ts';
  import { state, activeStep, steps } from '../stores/state.store.ts';

  export let stripe;
  export let donation;

  let element;
  let prButton;
  let isSupported = false;
  let paymentReqSub;

  onMount(() => {
    paymentReqSub = state.subscribe(state => {
      const paymentRequest = stripe.paymentRequest(state.paymentOptions);

      var elements = stripe.elements({ locale: getLocale() });
      prButton = elements.create('paymentRequestButton', {
        paymentRequest,
        style: { paymentRequestButton: { type: 'donate', theme: 'light-outline', height: '64px' } },
      });

      // Check the availability of the Payment Request API first.
      paymentRequest.canMakePayment().then(result => {
        if (result) {
          isSupported = true;
          prButton.mount(element);
        } else {
          isSupported = false;
        }
      });

      paymentRequest.on('token', event => {
        handleExpressDonation(event, state.slug, state.amount, state.isMonthly, donation)
          .then(response => {
            event.complete('success');
            activeStep.set(steps.Thanks);
            donation.name = event.payerName;
          })
          .catch(response => {
            event.complete('fail');
          });
      });
    });
  });

  onDestroy(() => {
    paymentReqSub();

    if (prButton) {
      prButton.unmount();
    }
  });
</script>

{#if isSupported}
  <h2>
    {@html t('Express Checkout')}
  </h2>
{/if}
<div bind:this={element} id="payment-request-button" />
{#if isSupported}
  <p class="uweo-donation__or">
    <span>
      {@html t('Or')}
    </span>
  </p>
{/if}
