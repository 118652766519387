import { Interval } from './interval.enum';
import { postDonation } from './post-donation';
export function handleExpressDonation(stripeRes, campaignId, amount, isMonthly, donation) {
    const payload = {
        id: null,
        type: 'donations',
        attributes: Object.assign(Object.assign({}, donation), { name: stripeRes.payerName, email: stripeRes.payerEmail, amount: amount, interval: isMonthly ? Interval.Month : Interval.None, token: stripeRes.token.id, phone: stripeRes.payerPhone, country: stripeRes.token.card.address_country, city: stripeRes.token.card.address_city, address1: stripeRes.token.card.address_line1, state: stripeRes.token.card.address_state, postal_code: stripeRes.token.card.address_zip, instructions: null }),
    };
    return postDonation(campaignId, payload);
}
