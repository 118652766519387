let locale;
export var Locale;
(function (Locale) {
    Locale["English"] = "en-CA";
    Locale["French"] = "fr-CA";
})(Locale || (Locale = {}));
export function getLocale() {
    if (!locale) {
        locale = document.documentElement.lang.toLowerCase().startsWith('fr') ? Locale.French : Locale.English;
    }
    return locale;
}
export function apiLang() {
    if (!locale) {
        getLocale();
    }
    switch (locale) {
        case Locale.English: {
            return 'en';
        }
        case Locale.French: {
            return 'fr';
        }
    }
}
