<script>
  import { onMount } from 'svelte';

  import { t } from '../utils/index.ts';

  export let donation;
  export let ecards;

  onMount(() => (donation.dedication.ecard = ecards[0].cards[0].id));
</script>

<h3>{t('Choose a design for your card.')}</h3>
{#each ecards as group}
  <h4 class="uweo-donation__ecards-name-heading">{group.name}</h4>
  <ul class="uweo-flex-columns-wrapper uweo-donation__ecards-list" style="display: flex; flex-wrap: wrap;">
    {#each group.cards as card}
      <li class="uweo-donation__ecard">
        <input
          type="radio"
          id={card.id}
          value={card.id}
          bind:group={donation.dedication.ecard}
          class:active={donation.dedication.ecard == card.id} />
        <label for={card.id}>
          <img style="width: 100%; pointer-events: none;" alt={card.attributes.name} src={card.attributes.image} />
        </label>
      </li>
    {/each}
  </ul>
{/each}
