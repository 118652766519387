<script lang="ts">
  import gneoIcon1 from "../assets/gneo-icon1.png";
  import gneoIcon2 from "../assets/gneo-icon2.png";
  import gneoIcon3 from "../assets/gneo-icon3.png";
  import { t } from "../utils/translations";
</script>

<div class="bg-stone-100 p-6">
  <h2 class="mb-6 text-2xl font-bold text-center">{t("Why give monthly")}</h2>
  <ul class="flex flex-wrap p-0">
    <li class="card">
      <img class="card__img" alt="" src={gneoIcon1} />
      <p>
        {t(
          "You’re investing in causes you care about. Plus, you’ll receive special perks, like discounts on our events."
        )}
      </p>
    </li>
    <li class="card">
      <img class="card__img" alt="" src={gneoIcon2} />
      <p>
        {t(
          "You’re supporting United Way East Ontario’s 100% local initiatives that bring measurable change for our communities’ most vulnerable people. That’s a big deal."
        )}
      </p>
    </li>
    <li class="card">
      <img class="card__img" alt="" src={gneoIcon3} />
      <p>
        {t(
          "Your ongoing donation provides reliable funds needed to fuel lasting change across our region."
        )}
      </p>
    </li>
  </ul>
</div>

<style lang="postcss">
  .card {
    @apply basis-64 flex flex-col items-center w-full px-6 py-10 mx-auto bg-white rounded-md text-center my-1;
  }

  .card__img {
    @apply w-24 h-24 mb-6 rounded-full;
  }
</style>
