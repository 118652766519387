<script lang="ts">
  import { onDestroy } from "svelte";
  import { fly } from "svelte/transition";
  import DonationInfo from "./components/DonationInfo.svelte";
  import Errors from "./components/Errors.svelte";
  import Footer from "./components/Footer.svelte";
  import StepIndicator from "./components/StepIndicator.svelte";
  import { env } from "./environments/environment";
  import AmountStep from "./steps/AmountStep.svelte";
  import GiftOptionsStep from "./steps/GiftOptionsStep.svelte";
  import InfoStep from "./steps/InfoStep.svelte";
  import PaymentStep from "./steps/PaymentStep.svelte";
  import ThankYouStep from "./steps/ThankYouStep.svelte";
  import { activeStep, amount, isMonthly, steps } from "./stores/state.store";
  import { apiLang } from "./utils/index";

  export let attributes;
  export let bgImage;
  export let donation;
  export let ecards;
  export let causes;
  export let thankYouRedirect;

  let contentPane;

  const stripe = Stripe(attributes.payment_gateway.public_key);

  const unsub = activeStep.subscribe(() => {
    if (contentPane && contentPane.scrollTo) {
      contentPane.scrollTo({ top: 0, behavior: "smooth" });
    }
  });

  onDestroy(() => {
    unsub();
  });

  function handleImageLoad(event: Event): void {
    (event.target as HTMLImageElement).style.opacity = "1";
  }

  function onClose() {
    const today = new Date();
    const currentYear = today.getFullYear();
    const currentMonth = today.getMonth();

    const holidayStart = new Date(
      `${currentMonth < 6 ? currentYear - 1 : currentYear}/12/01`
    );
    const holidayEnd = new Date(
      `${currentMonth < 6 ? currentYear : currentYear + 1}/01/03`
    );

    // HACK: Disable this for now...
    // const isHoliday = today >= holidayStart && today <= holidayEnd;
    const isHoliday = false;

    if (donation.submitted && thankYouRedirect.en && thankYouRedirect.fr) {
      // Redirect to thank you URL if provided by params...
      const location =
        apiLang() === "en" ? thankYouRedirect.en : thankYouRedirect.fr;
      window.location.replace(location);
    } else if (donation.submitted && isHoliday) {
      // Otherwise use holiday thank you if we're between the holiday start/end posts...
      const location =
        apiLang() === "en"
          ? "https://www.unitedwayeo.ca/holiday/thank-you/"
          : "https://www.centraideeo.ca/fetes/merci/";
      window.location.replace(location);
    } else if (donation.submitted) {
      console.log(donation.interval);
      if ($isMonthly) {
        window.location.replace(env.thankYouUrl[apiLang()].month);
      } else {
        window.location.replace(env.thankYouUrl[apiLang()].none);
      }
    } else {
      // Otherwise... Just close the app...
      window.location.hash = "";
    }
  }
</script>

<div class="uweo-donation-app gennext-donation-app" style="z-index: 10;">
  <div
    class="uweo-donation__pane pane--image bg-black/30"
    style="overflow:hidden; position: relative;"
  >
    <img
      on:load={handleImageLoad}
      src={bgImage.default}
      alt=""
      aria-hidden
      style="opacity: 0; position: absolute; top: 50%; left: 50%; height: 100%; width: auto; transform: translate(-50%, -50%); transition:
      opacity 1s linear; max-width: none;"
    />
  </div>
  <div class="uweo-donation__pane pane--content" bind:this={contentPane}>
    <img
      class="uweo-donation__mobile-image"
      on:load={handleImageLoad}
      src={bgImage.mobile}
      alt=""
      aria-hidden
      style="max-width: 100%;"
    />
    <div class="uweo-donation__button-cont">
      <button class="uweo-donation__button button--close" on:click={onClose}>
        <img
          src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHdpZHRoPSc5NCcgaGVpZ2h0PScxNTAnIHZpZXdCb3g9JzAgMCAzMjAgNTEyJz48cGF0aCBzdHlsZT0iZmlsbDojODc4Nzg3IiBkPSdNMTkzLjk0IDI1NkwyOTYuNSAxNTMuNDRsMjEuMTUtMjEuMTVjMy4xMi0zLjEyIDMuMTItOC4xOSAwLTExLjMxbC0yMi42My0yMi42M2MtMy4xMi0zLjEyLTguMTktMy4xMi0xMS4zMSAwTDE2MCAyMjIuMDYgMzYuMjkgOTguMzRjLTMuMTItMy4xMi04LjE5LTMuMTItMTEuMzEgMEwyLjM0IDEyMC45N2MtMy4xMiAzLjEyLTMuMTIgOC4xOSAwIDExLjMxTDEyNi4wNiAyNTYgMi4zNCAzNzkuNzFjLTMuMTIgMy4xMi0zLjEyIDguMTkgMCAxMS4zMWwyMi42MyAyMi42M2MzLjEyIDMuMTIgOC4xOSAzLjEyIDExLjMxIDBMMTYwIDI4OS45NCAyNjIuNTYgMzkyLjVsMjEuMTUgMjEuMTVjMy4xMiAzLjEyIDguMTkgMy4xMiAxMS4zMSAwbDIyLjYzLTIyLjYzYzMuMTItMy4xMiAzLjEyLTguMTkgMC0xMS4zMUwxOTMuOTQgMjU2eicvPjwvc3ZnPg=="
          alt="Close"
        />
      </button>
    </div>
    <!-- <ul>
      <li class:active={$activeStep === steps.Amount}>Amount</li>
      <li class:active={$activeStep === steps.Info || $activeStep === steps.Payment}>Payment</li>
    </ul> -->

    <div class="uweo-donation__steps" style="overflow-x:hidden;">
      {#if $activeStep === steps.Amount}
        <div
          in:fly={{ x: 500, delay: 500 }}
          out:fly={{ x: -500, duration: 250 }}
        >
          <div class="uweo-donation__step">
            <StepIndicator />
            <AmountStep {...attributes.details} />
          </div>
          <Footer />
        </div>
      {:else if $activeStep === steps.Options}
        <div
          in:fly={{ x: 500, delay: 500 }}
          out:fly={{ x: -500, duration: 250 }}
        >
          <div class="uweo-donation__step">
            <StepIndicator />
            <Errors />
            <DonationInfo bind:donation />
            <GiftOptionsStep
              bind:donation
              bind:attributes
              bind:ecards
              bind:causes
            />
          </div>
          <Footer />
        </div>
      {:else if $activeStep === steps.Info}
        <div
          in:fly={{ x: 500, delay: 500 }}
          out:fly={{ x: -500, duration: 250 }}
        >
          <div class="uweo-donation__step">
            <StepIndicator />
            <Errors />
            <DonationInfo bind:donation />
            <InfoStep {stripe} bind:donation />
          </div>
          <Footer />
        </div>
      {:else if $activeStep === steps.Payment}
        <div
          in:fly={{ x: 500, delay: 500 }}
          out:fly={{ x: -500, duration: 250 }}
        >
          <div class="uweo-donation__step">
            <StepIndicator />
            <Errors />
            <DonationInfo bind:donation />
            <PaymentStep
              {stripe}
              bind:donation
              amount={$amount}
              isMonthly={$isMonthly}
              campaignId={attributes.slug}
            />
          </div>
          <Footer />
        </div>
      {:else if $activeStep === steps.Thanks}
        <div
          in:fly={{ x: 500, delay: 500 }}
          out:fly={{ x: -500, duration: 250 }}
        >
          <div class="uweo-donation__step">
            <ThankYouStep bind:donation on:close={onClose} />
          </div>
          <Footer />
        </div>
      {/if}
    </div>
  </div>
</div>

<style global lang="postcss">
  .gennext-donation-app {
    @tailwind base;
    @apply leading-normal font-sans antialiased;
  }
  @tailwind components;
  @tailwind utilities;

  @layer base {
    button {
      @apply cursor-pointer;
    }
  }

  .link {
    @apply text-current hover:text-uw-600 decoration-0 no-underline;
  }

  .input {
    @apply border-2 border-stone-300 px-3 py-1;
  }
</style>
