export var USState;
(function (USState) {
    USState["Alabama"] = "AL";
    USState["Alaska"] = "AK";
    USState["Arizona"] = "AZ";
    USState["Arkansas"] = "AR";
    USState["California"] = "CA";
    USState["Colorado"] = "CO";
    USState["Connecticut"] = "CT";
    USState["Delaware"] = "DE";
    USState["Florida"] = "FL";
    USState["Georgia"] = "GA";
    USState["Hawaii"] = "HI";
    USState["Idaho"] = "ID";
    USState["Illinois"] = "IL";
    USState["Indiana"] = "IN";
    USState["Iowa"] = "IA";
    USState["Kansas"] = "KS";
    USState["Kentucky"] = "KY";
    USState["Louisiana"] = "LA";
    USState["Maine"] = "ME";
    USState["Maryland"] = "MD";
    USState["Massachusetts"] = "MA";
    USState["Michigan"] = "MI";
    USState["Minnesota"] = "MN";
    USState["Mississippi"] = "MS";
    USState["Missouri"] = "MO";
    USState["Montana"] = "MT";
    USState["Nebraska"] = "NE";
    USState["Nevada"] = "NV";
    USState["NewHampshire"] = "NH";
    USState["NewJersey"] = "NJ";
    USState["NewMexico"] = "NM";
    USState["NewYork"] = "NY";
    USState["NorthCarolina"] = "NC";
    USState["NorthDakota"] = "ND";
    USState["Ohio"] = "OH";
    USState["Oklahoma"] = "OK";
    USState["Oregon"] = "OR";
    USState["Pennsylvania"] = "PA";
    USState["RhodeIsland"] = "RI";
    USState["SouthCarolina"] = "SC";
    USState["SouthDakota"] = "SD";
    USState["Tennessee"] = "TN";
    USState["Texas"] = "TX";
    USState["Utah"] = "UT";
    USState["Vermont"] = "VT";
    USState["Virginia"] = "VA";
    USState["Washington"] = "WA";
    USState["WestVirginia"] = "WV";
    USState["Wisconsin"] = "WI";
    USState["Wyoming"] = "WY";
    USState["DC"] = "DC";
    USState["AmericanSamoa"] = "AS";
    USState["Guam"] = "GU";
    USState["NorthernMarianaIslands"] = "MP";
    USState["PuertoRico"] = "PR";
    USState["VirginIslands"] = "VI";
})(USState || (USState = {}));
export const USStateDictionary = {
    [USState.Alabama]: 'Alabama',
    [USState.Alaska]: 'Alaska',
    [USState.Arizona]: 'Arizona',
    [USState.Arkansas]: 'Arkansas',
    [USState.California]: 'California',
    [USState.Colorado]: 'Colorado',
    [USState.Connecticut]: 'Connecticut',
    [USState.Delaware]: 'Delaware',
    [USState.Florida]: 'Florida',
    [USState.Georgia]: 'Georgia',
    [USState.Hawaii]: 'Hawaii',
    [USState.Idaho]: 'Idaho',
    [USState.Illinois]: 'Illinois',
    [USState.Indiana]: 'Indiana',
    [USState.Iowa]: 'Iowa',
    [USState.Kansas]: 'Kansas',
    [USState.Kentucky]: 'Kentucky',
    [USState.Louisiana]: 'Louisiana',
    [USState.Maine]: 'Maine',
    [USState.Maryland]: 'Maryland',
    [USState.Massachusetts]: 'Massachusetts',
    [USState.Michigan]: 'Michigan',
    [USState.Minnesota]: 'Minnesota',
    [USState.Mississippi]: 'Mississippi',
    [USState.Missouri]: 'Missouri',
    [USState.Montana]: 'Montana',
    [USState.Nebraska]: 'Nebraska',
    [USState.Nevada]: 'Nevada',
    [USState.NewHampshire]: 'New Hampshire',
    [USState.NewJersey]: 'New Jersey',
    [USState.NewMexico]: 'New Mexico',
    [USState.NewYork]: 'New York',
    [USState.NorthCarolina]: 'North Carolina',
    [USState.NorthDakota]: 'North Dakota',
    [USState.Ohio]: 'Ohio',
    [USState.Oklahoma]: 'Oklahoma',
    [USState.Oregon]: 'Oregon',
    [USState.Pennsylvania]: 'Pennsylvania',
    [USState.RhodeIsland]: 'Rhode Island',
    [USState.SouthCarolina]: 'South Carolina',
    [USState.SouthDakota]: 'South Dakota',
    [USState.Tennessee]: 'Tennessee',
    [USState.Texas]: 'Texas',
    [USState.Utah]: 'Utah',
    [USState.Vermont]: 'Vermont',
    [USState.Virginia]: 'Virginia',
    [USState.Washington]: 'Washington',
    [USState.WestVirginia]: 'West Virginia',
    [USState.Wisconsin]: 'Wisconsin',
    [USState.Wyoming]: 'Wyoming',
    [USState.DC]: 'District of Columbia',
    [USState.AmericanSamoa]: 'American Samoa',
    [USState.Guam]: 'Guam',
    [USState.NorthernMarianaIslands]: 'Northern Mariana Islands',
    [USState.PuertoRico]: 'Puerto Rico',
    [USState.VirginIslands]: 'Virgin Islands, U.S.',
};
export const States = Object.keys(USStateDictionary).map(key => ({
    label: USStateDictionary[key],
    value: key,
}));
