import { env } from "../../environments/environment";
import { apiLang } from "../locale";
export async function getCampaign(slug) {
    const response = await fetch(`${env.apiUrl}/campaigns/${slug}`, {
        headers: {
            "Accept-Language": apiLang(),
        },
    });
    const campaign = await response.json();
    return campaign;
}
