<script>
  import { onMount, createEventDispatcher } from 'svelte';
  import MaskInput from 'svelte-input-mask/MaskInput.svelte';
  import { env } from '../environments/environment.ts';
  import { CanadianProvince, Countries, Country, Provinces, States, t } from '../utils/index.ts';

  export let address;
  export let required;

  const dispatch = createEventDispatcher();

  onMount(() => {
    address.name = address.name || null;
    address.address1 = address.address1 || null;
    address.address2 = address.address2 || null;
    address.city = address.city || null;
    address.country = address.country || Country.Canada;
    address.postal_code = address.postal_code || null;

    if (!address.state) {
      onCountryChange();
    }
  });

  function onCountryChange() {
    if (address.country === Country.Canada) {
      address.state = CanadianProvince.Ontario;
    } else {
      address.state = States[0].key;
    }
  }

  function handlePostalChange(event) {
    address.postal_code = event.detail.inputState.maskedValue;
  }

  function handlePostalFocus(event) {
    window.setTimeout(() => {
      address.postal_code = event.detail.target.value;
    }, 0);
  }

  function inputUpdate() {
    dispatch('inputUpdate');
  }
</script>

<svelte:head>
  {#if env.addressCompleteKey}
    <link
      rel="stylesheet"
      type="text/css"
      href="https://ws1.postescanada-canadapost.ca/css/addresscomplete-2.30.min.css?key={env.addressCompleteKey}"
    />
    <script
      type="text/javascript"
      src="https://ws1.postescanada-canadapost.ca/js/addresscomplete-2.30.min.js?key={env.addressCompleteKey}"></script>
  {/if}
</svelte:head>

<div class="uweo-donation__form-group">
  <label for="name">
    <small>
      {@html t('Name')}
    </small>
  </label>
  <input type="text" id="name" {required} bind:value={address.name} on:input={inputUpdate} on:focus={inputUpdate} />
</div>

<div class="uweo-donation__form-row">
  <div class="uweo-donation__col-60">
    <div class="uweo-donation__form-group">
      <label for="address1">
        <small>
          {@html t('Address Line 1')}
        </small>
      </label>
      <input
        type="text"
        id="address1"
        name="address1"
        {required}
        bind:value={address.address1}
        on:input={inputUpdate}
        on:focus={inputUpdate}
      />
    </div>
  </div>
  <div class="uweo-donation__col-40">
    <div class="uweo-donation__form-group">
      <label for="address2">
        <small>
          {@html t('Address Line 2')}
        </small>
        <span class="uweo-donation__label-extra-help">
          {@html t('(optional)')}
        </span>
      </label>
      <input type="text" id="address2" name="address2" bind:value={address.address2} on:input={inputUpdate} on:focus={inputUpdate} />
    </div>
  </div>
</div>

<div class="uweo-donation__form-group">
  <label for="city">
    <small>
      {@html t('City / Town')}
    </small>
  </label>
  <input type="text" id="city" name="city" {required} bind:value={address.city} on:input={inputUpdate} on:focus={inputUpdate} />
</div>

<div class="uweo-donation__form-row">
  <div class="uweo-donation__col-33">
    <div class="uweo-donation__form-group">
      <label for="country">
        <small>
          {@html t('Country / Region')}
        </small>
      </label>
      <select
        id="country"
        name="country"
        {required}
        bind:value={address.country}
        on:change={onCountryChange}
        on:input={inputUpdate}
        on:focus={inputUpdate}
      >
        {#each Countries as country}
          <option value={country.value}>{t(country.label)}</option>
        {/each}
      </select>
    </div>
  </div>
  <div class="uweo-donation__col-33">
    <div class="uweo-donation__form-group">
      {#if address.country === Country.Canada}
        <label for="state">
          <small>
            {@html t('Province')}
          </small>
        </label>
        <select id="state" name="state" {required} bind:value={address.state} on:input={inputUpdate} on:focus={inputUpdate}>
          {#each Provinces as province}
            <option value={province.value}>{t(province.label)}</option>
          {/each}
        </select>
      {:else if address.country === Country.USA}
        <label for="state">
          <small>
            {@html t('State')}
          </small>
        </label>
        <select id="state" name="state" {required} bind:value={address.state} on:input={inputUpdate} on:focus={inputUpdate}>
          {#each States as state}
            <option value={state.value}>{t(state.label)}</option>
          {/each}
        </select>
      {/if}
    </div>
  </div>
  <div class="uweo-donation__col-33">
    <div class="uweo-donation__form-group">
      <label for="postal_code">
        <small>
          {@html t('Postal / Zip code')}
        </small>
      </label>
      <MaskInput
        type="text"
        id="postal_code"
        name="postal_code"
        required
        mask={address.country === Country.USA ? '00000' : 'a0a 0a0'}
        size={20}
        showMask
        maskChar="_"
        value={address.postal_code || undefined}
        on:focus={(evt) => {
          handlePostalFocus(evt);
          inputUpdate();
        }}
        on:change={handlePostalChange}
        on:input={(evt) => {
          handlePostalFocus(evt);
          inputUpdate();
        }}
        autocomplete="off"
      />
    </div>
  </div>
</div>
