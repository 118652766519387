import 'stripe-v3';
import App from './App.svelte';
import { activeStep, amount, errors, isMonthly, slug, steps } from './stores/state.store';
import { env } from './environments/environment';
import { getEcardOptions } from './utils/api/get-ecard-options';
import { getCampaign, loadScript, parseParms } from './utils/index';
// Polyfills
if (!String.prototype.startsWith) {
    Object.defineProperty(String.prototype, 'startsWith', {
        value: function (search, rawPos) {
            var pos = rawPos > 0 ? rawPos | 0 : 0;
            return this.substring(pos, pos + search.length) === search;
        },
    });
}
//
// Track `app` as a singleton.
let app;
let styles = document.body.style.cssText;
/**
 * Create or destroy the app based on the current hash
 *
 * @export
 * @param {Event} event
 */
export function toggle() {
    const hash = window.location.hash && window.location.hash.substring(1);
    const shouldLaunch = hash && hash.startsWith('donate');
    if (shouldLaunch) {
        loadScript(`https://www.google.com/recaptcha/api.js?render=${env.recaptchaKey}`, () => loadScript('https://js.stripe.com/v3/', () => init(hash && hash.split('?')[1])));
        document.body.style.paddingRight = window.innerWidth - document.body.clientWidth + 'px';
        document.body.style.overflow = 'hidden';
        document.body.style.setProperty('-webkit-overflow-scrolling', 'touch');
    }
    else {
        document.body.style.cssText = styles;
        if (app) {
            app.$destroy();
        }
    }
}
/**
 * Initialize the app with config derived from the querystring
 *
 * @export
 * @param {string} search
 */
export function init(search = '') {
    const params = parseParms(search);
    const amount = params['a'] ? parseInt(params['a'], 10) : NaN;
    const config = {
        c: params.c,
        m: params['m'] === 'true',
        a: isNaN(amount) ? undefined : amount,
        s: params['s'] === 'true',
        h: params['h'] === 'true',
        cause: params['cause'],
        tyEn: params['tyen'],
        tyFr: params['tyfr'],
    };
    loadScript('https://polyfill.io/v3/polyfill.min.js?features=Promise%2CPromise.prototype.finally%2Cfetch%2CString.prototype.startsWith', () => main(config));
    // if (browserSupportsAllFeatures()) {
    //   main(config);
    // } else {
    //   loadScript('https://cdn.jsdelivr.net/npm/whatwg-fetch@3.0.0/dist/fetch.umd.js', () => main(config));
    // }
}
/**
 * Bootstrap
 *
 * @param {Config} { c = 'uweo', m = false, a = 25 }
 */
async function main({ c = 'gennext', m = false, a = 50, s = false, h = false, cause, tyEn, tyFr }) {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    const target = document.querySelector('#uweo-donation-client') || document.body;
    const campaign = await getCampaign(c);
    const ecardOptions = await getEcardOptions();
    let causes;
    if (campaign.data.attributes.show_causes) {
        causes = campaign.included.filter((i) => i.type === 'causes');
    }
    let ecards;
    if (ecardOptions) {
        ecards = ecardOptions.data.reduce((g, ecard) => {
            if (!g[ecard.attributes.group]) {
                g[ecard.attributes.group] = [];
            }
            g[ecard.attributes.group].push(ecard);
            return g;
        }, {});
        ecards = Object.keys(ecards).map((key) => ({ name: key, cards: ecards[key] })) || [];
    }
    const donation = {
        'g-recaptcha-response': null,
        address1: null,
        amount: null,
        cause,
        city: null,
        country: null,
        email: null,
        instructions: '',
        interval: null,
        inTribute: h,
        name: null,
        phone_optin: false,
        phone: null,
        postal_code: null,
        state: null,
        token: null,
        workplace: null,
        dedication: {
            in_memory_of: null,
            ecard: null,
            hide_gift_amount: false,
            notify_sender: false,
            recipients_email: null,
            recipients_name: null,
            send_date: null,
            senders_message: '',
            senders_name: null,
            type: 'none',
        },
    };
    const media = campaign === null || campaign === void 0 ? void 0 : campaign.included.filter((m) => m.type === 'media').filter((m) => { var _a, _b; return ((_b = (_a = m === null || m === void 0 ? void 0 : m.attributes) === null || _a === void 0 ? void 0 : _a.name) === null || _b === void 0 ? void 0 : _b.toLowerCase()) === (window.donationImage || '').toLowerCase(); });
    const defaultMedia = campaign === null || campaign === void 0 ? void 0 : campaign.included.filter((m) => m.type === 'media').filter((m) => { var _a, _b; return ((_b = (_a = m === null || m === void 0 ? void 0 : m.attributes) === null || _a === void 0 ? void 0 : _a.name) === null || _b === void 0 ? void 0 : _b.toLowerCase()) === 'default'; });
    const props = {
        attributes: campaign.data.attributes,
        causes: causes || [],
        ecards,
        bgImage: {
            mobile: ((_b = (_a = media === null || media === void 0 ? void 0 : media.find((m) => { var _a; return ((_a = m === null || m === void 0 ? void 0 : m.attributes) === null || _a === void 0 ? void 0 : _a.collection) === 'mobile_campaigns'; })) === null || _a === void 0 ? void 0 : _a.links) === null || _b === void 0 ? void 0 : _b.self) ||
                ((_d = (_c = defaultMedia === null || defaultMedia === void 0 ? void 0 : defaultMedia.find((m) => { var _a; return ((_a = m === null || m === void 0 ? void 0 : m.attributes) === null || _a === void 0 ? void 0 : _a.collection) === 'mobile_campaigns'; })) === null || _c === void 0 ? void 0 : _c.links) === null || _d === void 0 ? void 0 : _d.self),
            default: ((_f = (_e = media === null || media === void 0 ? void 0 : media.find((m) => { var _a; return ((_a = m === null || m === void 0 ? void 0 : m.attributes) === null || _a === void 0 ? void 0 : _a.collection) === 'campaigns'; })) === null || _e === void 0 ? void 0 : _e.links) === null || _f === void 0 ? void 0 : _f.self) ||
                ((_h = (_g = defaultMedia === null || defaultMedia === void 0 ? void 0 : defaultMedia.find((m) => { var _a; return ((_a = m === null || m === void 0 ? void 0 : m.attributes) === null || _a === void 0 ? void 0 : _a.collection) === 'campaigns'; })) === null || _g === void 0 ? void 0 : _g.links) === null || _h === void 0 ? void 0 : _h.self),
        },
        donation,
        thankYouRedirect: {
            en: tyEn && decodeURIComponent(tyEn),
            fr: tyFr && decodeURIComponent(tyFr),
        },
    };
    // Pre-load stores
    slug.set(campaign.data.attributes.slug);
    amount.set(a);
    isMonthly.set(m);
    activeStep.set(s ? steps.Options : steps.Amount);
    errors.set([]);
    // Replace existing app
    if (app) {
        app.$destroy();
    }
    app = new App({ target, props });
}
// Listen to relevant window events
window.addEventListener('load', toggle);
window.addEventListener('hashchange', toggle);
