import { derived, writable } from "svelte/store";
import { formatCurrency } from "../utils/format-currency";
export const steps = {
    Amount: "amount",
    Options: "options",
    Info: "info",
    Payment: "payment",
    Thanks: "thanks",
};
export const singleAmounts = [25, 50, 60, 100, 250];
export const monthlyAmounts = [5, 10, 15, 25, 35];
export const amounts = [...singleAmounts, ...monthlyAmounts];
// Writables
export const slug = writable("uweo");
export const activeStep = writable(steps.Amount);
export const amount = writable(null);
export const isMonthly = writable(true);
export const errors = writable([]);
// Readables
export const amountDspl = derived(amount, ($amount) => formatCurrency($amount));
// TODO: Get actual fees %... 8% was arbitrarily chosen
export const feesDspl = derived(amount, ($amount) => formatCurrency($amount * 0.08));
export const state = derived([amount, isMonthly, slug], ([amount$, isMonthly$, slug$]) => ({
    amount: amount$,
    isMonthly: isMonthly$,
    slug: slug$,
    paymentOptions: {
        country: "CA",
        currency: "cad",
        total: {
            label: "Donation",
            amount: amount$ * 100,
        },
        requestPayerName: true,
        requestPayerEmail: true,
        requestPayerPhone: true,
    },
}));
