const scripts = [];
export function loadScript(src, done) {
    if (scripts.includes(src)) {
        done();
        return;
    }
    const element = document.createElement('script');
    element.src = src;
    element.onload = () => {
        scripts.push(src);
        done();
    };
    element.onerror = () => {
        done(new Error('Failed to load script ' + src));
    };
    document.head.appendChild(element);
}
export default loadScript;
