<script>
  import { errors } from '../stores/state.store.ts';
</script>

{#if $errors.length > 0}
  <ul class="uweo-donation__error-list">
    {#each $errors as err}
      <li class="uweo-donation__error">{err}</li>
    {/each}
  </ul>
{/if}
