<script>
  import { formatCurrency } from '../utils/index.ts';
  import { amount as donationAmount } from '../stores/state.store.ts';

  export let amount;

  function onClick() {
    donationAmount.set(amount);
  }
</script>

<button class="uweo-donation__amount-button" type="button" on:click={onClick} class:active={amount === $donationAmount} value={amount}>
  {formatCurrency(amount, 0)}
</button>
