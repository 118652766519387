<script>
  import { createEventDispatcher, onMount } from 'svelte';
  import { t } from '../utils/index.ts';

  export let donation;

  const dispatcher = createEventDispatcher();

  onMount(() => {
    donation.submitted = true;
  });
</script>

<div style="text-align:center;">
  <h1>
    <span>
      {@html t('Thank you for inspiring change, ')}
    </span>
    <span style="white-space: nowrap;">{donation.name}.</span>
  </h1>
  <p>
    {@html t('Take pride in your decision to donate to United Way East Ontario. By trusting us with your gift, you’re breaking down barriers, improving lives, and creating opportunities for the people who need us most.')}
  </p>
  <p>
    <b>
      {@html t('A confirmation with additional details has been sent to you by email.')}
    </b>
  </p>
  <div class="uweo-donation__actions">
    <button class="uweo-donation__next-step-button uweo-donation__donate-button" on:click={() => dispatcher('close')}>
      <span>
        {@html t('Close')}
      </span>
    </button>
  </div>
</div>
